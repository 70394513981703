/*
        SUMMARY
            0 - Const / Variables
            1 - Function
*/


/*---------------------/ 1 - Const - Variables /-------------------*/


/*---------------------/ 2 - Functions /-------------------*/

// p/ função startAutoChangeEnterprise()
let currentId = 1;
let autoChangeInterval;

$(() => {
    changeEnterpriseHero();
    startAutoChangeEnterprise();
})

// muda o empreendimento ao clicar nas arrows
function changeEnterpriseHero() {
    let arrowLeft = $("[content].arrow-left");
    let arrowRight = $("[content].arrow-right");

    // verifica se as arrows estão habilitadas
    arrowLeft.on('click', function() {
        if (!$(this).hasClass("disabled")) {
            toggleEnterprise($(this));
        }
    });

    arrowRight.on('click', function() {
        if (!$(this).hasClass("disabled")) {
            toggleEnterprise($(this));
        }
    });
}

// troca de empreendimentos
function toggleEnterprise(element) {
    let id = element.attr('content');
    
    $("[content]").removeClass('active');
    $(element).addClass("active");
    
    $("[contentText]").addClass('d-none');
    $("[contentGallery]").addClass('d-none');
    $("[contentText=" + id + "]").removeClass('d-none');
    $("[contentGallery=" + id + "]").removeClass('d-none');

    // reseta animações ao trocar empreendimentos
    if ($('.content').hasClass('d-none')) {
        $("[contentText]").removeClass("animated-element");
        $("[contentGallery]").removeClass("animated-element");
    }

    // desabilita caso não haja seguinte
    if (id == 1) {
        $(".arrow-left").addClass("disabled");
    } else {
        $(".arrow-left").removeClass("disabled");
    }

    if (id == 2) {
        $(".arrow-right").addClass("disabled");
    } else {
        $(".arrow-right").removeClass("disabled");
    }

    // ativa os .numbers
    let numbers = $(".numbers .number");

    $(numbers).each((i, number) => {
        if ((id == 1 && i == 0) || (id == 2 && i == 1)) {
            $(number).addClass("active");
        } else {
            $(number).removeClass("active");
        }
    });

    // reinicia o autoplay
    resetAutoChangeEnterprise();
}

// inicia o autoplay
function startAutoChangeEnterprise() {
    autoChangeInterval = setInterval(() => {
        currentId = currentId === 1 ? 2 : 1;
        
        let element = $("[content=" + currentId + "]");

        toggleEnterprise(element);
    }, 9000);
}

// reinicia o autoplay
function resetAutoChangeEnterprise() {
    clearInterval(autoChangeInterval);
    startAutoChangeEnterprise();
}


// hero slider
const swiperHero = new Swiper('.swiper-hero', {
    slidesPerView: 1,
    loop: true,
    speed: 1250,
    autoplay: true,
    autoplaySpeed: 3000,
    lazyPreloadPrevNext: 1,
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },
})

// product slider 1
const swiperPiazza = new Swiper('.swiper-product-1', {
    slidesPerView: 1,
    loop: true,
    speed: 1250,
    lazyPreloadPrevNext: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },
})

// cards slider mobile 1
const swiperPiazzaCards = new Swiper('.swiper-mobile-cards', {
    slidesPerView: "auto",
    loop: false,
    speed: 800,
    spaceBetween: 13,
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },
    navigation: {
        nextEl: ".arrows .arrow-right-3",
        prevEl: ".arrows .arrow-left-3",
    },
})

// planta slider 1
const swiperPlantaPiazza = new Swiper('.swiper-planta-1', {
    slidesPerView: 1,
    effect: "fade",
    loop: true,
    speed: 800,
    lazyPreloadPrevNext: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    navigation: {
        nextEl: ".arrows .arrow-right-1",
        prevEl: ".arrows .arrow-left-1",
    },
})

// product slider 2
const swiperSense = new Swiper('.swiper-product-2', {
    slidesPerView: 1,
    loop: true,
    speed: 1250,
    lazyPreloadPrevNext: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },
})

// cards slider mobile 2
const swiperSenseCards = new Swiper('.swiper-mobile-cards-2', {
    slidesPerView: "auto",
    loop: false,
    speed: 800,
    spaceBetween: 13,
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },
    navigation: {
        nextEl: ".arrows .arrow-right-4",
        prevEl: ".arrows .arrow-left-4",
    },
})

// planta slider 2
const swiperPlantaSense = new Swiper('.swiper-planta-2', {
    slidesPerView: 1,
    effect: "fade",
    loop: true,
    speed: 800,
    lazyPreloadPrevNext: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    navigation: {
        nextEl: ".arrows .arrow-right-2",
        prevEl: ".arrows .arrow-left-2",
    },
})